<template>
    <div class="header">
        <div class="user-info">
            <img class="user-img" v-lazy="avatar+'?x-image-process=style/thumb.png'" />
            <div class="user-txt">
                <div class="user-name">{{username}}</div>
                <div v-if="phone" class="user-phone">{{phone}}</div>
                <div v-else class="user-phone">尚未绑定手机号，<router-link to="/fund/my/bindPhone"><span>点击去绑定</span></router-link></div>
            </div>
        </div>
        <div class="user-banner-wapper">
            <router-link to="/fund/my/helped">
                <img class="user-mysupport" src="../img/banner-mysupport.png"
            /></router-link>
            <router-link to="/fund/my/created"
                ><img class="user-myproject" src="../img/banner-myproject.png"
            /></router-link>
        </div>
    </div>
</template>

<script>
export default {
  props:['avatar','username','phone'],
  computed:{
      getAvatar:function(){
        //   return this.avatar.indexOf('jsb')
      }
  }
};
</script>

<style lang="less" scoped>
.header {
    background-image: url('../img/top-bg.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    height: 206px;

    overflow: hidden;

    .user-info {
        display: flex;
        margin: 28px 20px 0;

        .user-img {
            width: 57px;
            height: 57px;
            border: 2px solid #93dbff;
            border-radius: 28px;
        }

        .user-txt {
            color: #fff;
            text-align: left;
            margin-top: 3px;
            margin-left: 16px;

            .user-name {
                font-size: 18px;
            }
            .user-phone {
                font-size: 14px;
                color: #d8d8d8;
                margin-top: 8px;
                line-height: 14px;
                span{
                  color: #d8d8d8;
                  text-decoration:underline;
                }
            }
        }
    }

    .user-banner-wapper {
        display: flex;
        justify-content: center;
        margin-top: 25px;
        .user-mysupport {
            width: 164px;
            height: 70px;
            // margin-right: 15px;
        }
        .user-myproject {
            width: 164px;
            height: 70px;
            margin-left: 15px;
        }
    }
}
</style>