<template>
    <div v-if="user_nickname" class="my-wrapper">
        <topheader :username="user_nickname" :avatar="user_avatar" :phone="user_phone"></topheader>
        <div class="project-control">
            <listItem
                class="first-item"
                pic="icon-money.png"
                txt="提现记录"
                url="/fund/withdraw/records"
            ></listItem>
            <!-- <listItem
                pic="icon-guide.png"
                txt="筹款攻略"
                url="/fund/publish/guide"
            ></listItem>
            <listItem
                pic="icon-help.png"
                txt="帮助中心"
                url="/fund/helpcenter/list"
            ></listItem> -->
        <!-- </div>
        <div class="user-help"> -->
            <listItem
                pic="icon-service.png"
                txt="客服咨询"
                url="/fund/my/service"
            ></listItem>
            <listItem
                pic="icon-setting.png"
                txt="设置"
                url="/fund/my/setting"
            ></listItem>
        </div>
        <NavBar :activeIndex="2" />
    </div>
</template>

<script>
import topheader from './cpt/top-header.vue';
import listItem from './cpt/list-item/index.vue';
import { authChecker, shareCtrl } from '@/common/js/mmcModule';
import NavBar from '../../../components/NavBar/index.vue';
import api from './api';
export default {
    data() {
        return {
            user_avatar: '',
            user_nickname: '',
            user_phone: '',
        };
    },
    components: {
        topheader,
        listItem,
        NavBar
    },
    created() {
        authChecker.check({ notneedphone: 1 }, (res) => {
            showLoading('加载中');

            api.getInfo().then((res) => {
                hideLoading();
                console.log(res);
                this.user_avatar = res.data.face_url;
                this.user_nickname = res.data.nick_name;
                this.user_phone = res.data.phone;
            });
            shareCtrl.init();
        });
    },
};
</script>

<style lang="less" scoped>
.my-wrapper {
    background-color: #eee;
    height: 100vh;
    .project-control {
        margin-top: 10px;
        /deep/ .first-item {
            // border-radius: 15px 15px 0 0;
        }
    }
    .user-help {
        margin-top: 14px;
    }
}
</style>
