<template>
    <div class="list-item">
        <router-link class="link" :to="url">
            <img class="item-img" v-lazy="require(`../../img/`+this.pic)" alt="" />
            <div class="item-txt">{{ txt }}</div>
            <img class="item-arrow" src="./icon-arrow.png" alt="" />
        </router-link>
    </div>
</template>

<script>
export default {
    props: ['txt', 'pic', 'url'],
    
};
</script>

<style lang="less" scoped>
.list-item {
    padding: 19px 16px;
    background-color: #fff;
    .link {
        display: flex;
        align-items: center;

        .item-img {
            width: 22px;
            height: 22px;
        }
        .item-txt {
            font-size: 14px;
            color: #333;
            flex: 1;
            text-align: left;
            line-height: 14px;
            margin-left: 10px;
        }
        .item-arrow {
            width: 7px;
            height: 10px;
        }
    }
}
</style>