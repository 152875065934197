import mmcAjax from "@/common/js/mmcAjax"
export default {
    getInfo(){
        let url = `${window.APIBasePath}mmc/passport/user-center`
        
        return mmcAjax({
            method: 'get',
            url,
          })
    },
   
}